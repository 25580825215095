<template lang="html">
  <div id="app">
    <header>
      <a href="https://www.bilibili.com" target="_blank">
        <img class="logo" src="./assets/Navbar_logo.png" />
      </a>
      <div class="menu-container">
        <div class="menu-list">
          <div class="menu-item"
              :class="{ 'is-active': $route.name === item.name || ($route.name === 'NewsDetail' && item.name === 'News')  }"
               v-for="(item, index) in menuList" :key="index" @click="goPage(item)">
            {{ item.title }}
            <span v-if="$route.name === item.name || ($route.name === 'NewsDetail' && item.name === 'News')" class="anchor"></span>
          </div>
        </div>
      </div>
    </header>
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
    <footer>
      <p>Copyright © 2009 - 2022 bilibili. All Rights Reserved.</p>
    </footer>
  </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
  name: 'Home',
  data() {
    return {
      menuList: [
        {
          name: 'Home',
          title: 'ホーム',
          path: '/home'
        },
        {
          name: 'Business',
          title: '企業情報',
          path: '/business'
        },
        {
          name: 'News',
          title: 'ニュース',
          path: '/news'
        },
        {
          name: 'Game',
          title: 'ゲーム事業',
          path: 'https://game.bilibili.co.jp'
        },
        {
          name: 'Contact',
          title: 'お問い合わせ',
          path: '/contact'
        },
      ]
    }
  },
  methods: {
    goPage({name, path}) {
      if(name === 'Game') {
        window.open(path, '_blank')
        return
      }
      this.$router.push({
        path,
      })
    }
  },
}
</script>

<style lang="less">
@import './style/reset.less';
@import './style/base.less';

* {
  -webkit-tap-highlight-color: transparent
}

body, html {
  box-sizing: border-box;
  margin: 0px;
  min-height: 100%;
  width: 100%;
  background: #fff;
  font-family: sans-serif, Arial;
}

#app {
  margin: 0 auto;
  padding: 0 10px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  > a {
    display: block;
  }
  .logo {
    display: inline-block;
    height: 38px;
  }
  .menu-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-end;
    box-sizing: content-box;
    width: 100%;
    height: 60px;
  }
  .menu-list {
    display: inline-flex;
    // padding: 0 4px;
    overflow-y: hidden;
    padding-bottom: 22%;
  }
  .menu-item {
    position: relative;
    flex-shrink: 0;
    margin-left: 45px;
    height: 60px;
    color: #212121;
    font-size: 16px;
    line-height: 60px;
    cursor: pointer;
    transition: .3s;
    &:hover {
      color: #32A4D5;
    }
  }
  .is-active {
    color: #32A4D5;
    font-weight: 600;
  }
  .anchor {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #32A4D5;
  }
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  border-top: 1px solid #EAEAEA;
  color: #999999;
  font-size: 14px;
  > p {
    text-align: center;
  }
}

.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

</style>
