// 2019-01-01
export const formatDate = (dateValue) => {
  if(!dateValue) return
  let date = new Date(dateValue * 1000)
  let Y = date.getFullYear() + '-'
  let M = formatNum(date.getMonth() + 1) + '-'
  let D = formatNum(date.getDate())
  return Y + M + D
}

function formatNum(num){
  return num < 10 ? '0' + num : num
}