import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'

import Homepage from './views/homepage'
import Business from './views/business'
import News from './views/news'
import NewsDetail from './views/newsDetail'
import Contact from './views/contact'
Vue.use(Router)

const createRouter = () =>(new Router({
    mode: 'history',
    routes: [
    { 
      path: '/', 
      redirect: '/home' 
    },
    {
      path: '/home',
      name: 'Home',
      component: Homepage
    }, 
    {
      path: '/business',
      name: 'Business',
      component: Business
    },
    {
      path: '/news',
      name: 'News',
      component: News
    }, 
    {
      path: '/news/:id',
      name: 'NewsDetail',
      component: NewsDetail
    }, 
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    }],
  }))

export default createRouter