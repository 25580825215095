import axios from 'axios'

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  }
})

//POST传参序列化(添加请求拦截器)
axiosInstance.interceptors.request.use(
  request => {
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  return Promise.reject(error)
})

export default axiosInstance
