<template>
  <div class="contact-container">
    <div class="banner-img">
      <img src="../assets/contact.png" alt="">
    </div>
    <div class="header">
      <p>お問い合わせ</p>
    </div>
    <div class="info-content">
      <div class="info-other">
        <p>電話：03-5643-0189(代表)</p>
        <p>受付時間：平日午前10時～午後7時</p>
      </div>
    </div>
    <div class="header">
      <p>所在地</p>
    </div>
    <div class="map-content">
      <p class="desc">東京都港区六本木7丁目18番18号　住友不動産六本木通ビル　9階(受付)　12階</p>
      <div class="map-box">
          <baidu-map class="map" :center="{lng: 139.728245, lat: 35.662920}" :zoom="18">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" type="BMAP_NAVIGATION_CONTROL_SMALL" :showZoomInfo="false"></bm-navigation>
            <bm-marker :position="{lng: 139.728245, lat: 35.662920}">
            </bm-marker>
          </baidu-map>
      </div>
    </div>
  </div>
</template>


<script>
import BaiduMap from 'vue-baidu-map'

import { mapState } from 'vuex'
export default {
  name: 'Contact',
  data() {
    return {
       active: false
    }
  },
  mounted () {
  },
  methods: {
    goPage({path}) {
      this.$router.push({
        path: path,
      })
    }
  },
}
</script>

<style lang="less">
.contact-container {
  min-height: calc(100vh - 70px - 126px);
  .banner-img {
    width: 100%;
    height: 427px;
    border-radius: 8px;
    background: #eee;
    > img {
      display: inline-block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }
  .header {
    margin-top: 32px;
    height: 45px;
    border-bottom: 1px solid #EAEAEA;
    color: #212121;
    line-height: 24px;
    line-height: 45px;
    > p {
      font-weight: 600;
      font-size: 28px;
    }
  }

  .info-content {
    margin-top: 34px;
    color: #212121;
    font-size: 14px;
    line-height: 24px;
    .info-email {
      margin-bottom: 32px;
      > a {
        color: #3baee5;
        text-decoration: underline;
      }
    }
    .info-other {
      color: #0F0F0F;
      font-size: 14px;
    }
  }

  .map-content {
    margin-top: 34px;
    color: #212121;
    font-size: 14px;
    line-height: 24px;
    .desc {
      margin-bottom: 20px;
    }
    .map-box {
      margin-bottom: 46px;
      width: 100%;
      height: 440px;
      background: #eee;
      > div {
        width: 100%;
        height: 440px;
      }
    }
  }
}

.anchorBL {
  display: none;
}
</style>
