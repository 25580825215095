<template>
  <div class="news-detail-container">
    <div class="header" @click="back">
      <img src="../assets/back.png" alt="back">
      back</div>
    <div class="detail-content">
      <div class="page" v-if="viewData">
        <p class="title">{{viewData.title}}</p>
        <span class="date">{{formatDate(viewData.ctime)}}</span>
        <div class="detail" v-html="viewData.content">
        </div>
      </div>
      <div class="news-list" v-if="newsList && newsList.length">
        <a class="item"
           v-for="(news, index) in newsList"
           :key="index"
           @click="$router.replace({name: 'NewsDetail', params: { id: news.id }})">
          <p class="title">{{news.title}}</p>
          <p class="date">{{ formatDate(news.ctime) }}</p>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { formatDate } from '../utils/index'

export default {
  name: 'News',
  data() {
    return {
      viewData: null,
      newsList: [],
    }
  },
  async created() {
    this.loadList()
    this.loadDetail(this.$route.params.id)
  },
  methods: {
    formatDate,
    async loadDetail (id) {
      try {
        const data = await this.$axios.get(`//api.bilibili.com/x/cms/news/view`, {
          params: {
            business_id: 1,
            id
          }
        })
        if(data.code === 0) {
          this.viewData = data.data
        }
      } catch (error) {
        this.viewData = null
      }
    },
    async loadList() {
      try {
        const data = await this.$axios.get(`//api.bilibili.com/x/cms/news`, {
          params: {
            business_id: 1,
            pn: 1,
            ps: 5
          }
        })
        if(data.code === 0) {
          const newsList = data.data.news_list &&  data.data.news_list.filter(item => item.id !== Number(this.$route.params.id))
          this.newsList = newsList.slice(0, 4)
        }
      } catch (error) {
        this.newsList = []
      }
    },
    back(id) {
      this.$router.back()
    }
  },
  async beforeRouteUpdate (to, from, next) {
    await this.loadDetail(to.params.id)
    this.loadList()
    next()
  },
}
</script>

<style lang="less">
.news-detail-container {
  min-height: calc(100vh - 230px);
  border-top: 1px solid #EAEAEA;

  .header {
    display: flex;
    align-items: center;
    height: 70px;
    color: #32A4D5;
    line-height: 70px;
    cursor: pointer;
    > img {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }

  .detail-content {
    display: flex;
    .page {
      flex: 1;
      margin-right: 52px;
      margin-bottom: 30px;
      .title {
        margin-bottom: 6px;
        color: #212121;
        word-break: break-all;
        font-weight: 600;
        font-size: 28px;
      }
      .date {
        display: inline-block;
        margin-bottom: 24px;
        color: #999999;
        font-size: 14px;
      }
      .detail {
        color: #212121;
        font-size: 14px;
        img {
          max-width: 100%;
        }
      }
    }
    .news-list {
      flex-wrap: wrap;
      padding: 20px 20px;
      width: 300px;
      height: 100%;
      border: 1px solid #E6E6E6;
      .item {
        display: inline-block;
        margin: 15px 0;
        width: 100%;
        &:hover .title {
          color: #32A4D5 ;
        }
      }
      .title {
        margin-bottom: 10px;
        color: #212121;
        word-wrap: break-word;
        word-break: break-all;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        transition: .3s;

      }
      .date {
        color: #999999;
        font-size: 14px;
      }
    }
  }

}
</style>
