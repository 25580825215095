

import Vue from 'vue'
import Meta from 'vue-meta'

import createStore from './vuex'
import createRouter from './router'
import App from './App.vue'
import axios from './utils/axios'
import { Popover } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import BaiduMap from 'vue-baidu-map'

Vue.use(Meta)
Vue.use(Popover)

Vue.use(BaiduMap, {
  ak: 'b1OOxWpwkU7fg96QVR2kOWxdNI2uyhlU'
})

Vue.prototype.$axios = axios

export function createApp (){
  
  const store = createStore()
  const router = createRouter()

  const app = new Vue({
    store,
    router,
    render: h => h(App)
  })
  return { app, router, store }
}
