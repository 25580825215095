<template>
  <div class="news-container">
    <div class="banner-img">
      <img src="../assets/3.png" alt="">
    </div>
    <div class="header">
      <p>最近のニュース</p>
    </div>
    <div class="new-content" v-if="newsList && newsList.length">
      <div class="new-item" v-for="(news, index) in newsList" :key="index" @click="goDetail(news.id)">
        <a class="title" :title="news.title">{{ news.title }}</a>
        <p class="date">{{ formatDate(news.ctime) }}</p>
      </div>
    </div>
    <div v-else class="empty-content">
      <img src="../assets/empty.png" alt="">
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { formatDate } from '../utils/index'

export default {
  name: 'News',
  data() {
    return {
      newsList: []
    }
  },
  async created() {
    try {
      const data = await this.$axios.get(`//api.bilibili.com/x/cms/news`, {
        params: {
          business_id: 1,
          pn: 1,
          ps: 8
        }
      })
      if(data.code === 0) {
        this.newsList = data.data.news_list
      }
    } catch (error) {
      this.newsList = []
    }
  },
  methods: {
    formatDate,
    goDetail(id) {
      this.$router.push({
        name: 'NewsDetail',
        params: {
          id
        }
      })
    }
  },
}
</script>

<style lang="less">
.news-container {
  min-height: calc(100vh - 70px - 126px);
  .banner-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    > img {
      display: inline-block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }
  .header {
    margin-top: 32px;
    height: 45px;
    border-bottom: 1px solid #EAEAEA;
    color: #212121;
    line-height: 24px;
    line-height: 45px;
    > p {
      font-weight: 600;
      font-size: 28px;
    }
  }

  .new-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 34px;
    color: #212121;
    font-size: 14px;
    line-height: 24px;
    .new-item {
      margin-bottom: 43px;
      width: 49%;
      .title {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        transition: .3s;
        &:hover {
          color: #32A4D5 ;
        }
      }
      .date {
        color: #999999;
        font-size: 14px;
      }
    }
  }

  .empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    > img {
      width: 100px;
    }
  }

}
</style>
