import { createApp } from './index'
import Vue from 'vue'
import '@babel/polyfill'

// sentry start
const { BrowserClient, Integrations } = Sentry
const appClient = new BrowserClient({
  dsn: `${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://fa76e4f290994f51ba92eb998c3a14ff@api.bilibili.com/x/traceback/12`,
  debug: process.env.NODE_ENV !== 'production',
  release: process.env.COMMIT_ID,
  environment: process.env.NODE_ENV,
  sampleRate: 1,
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: process.env.NODE_ENV !== 'production',
    })
  ]
})

// https://github.com/getsentry/sentry-javascript/issues/1854#issuecomment-510379723
Sentry.getCurrentHub().bindClient(appClient)

// sentry end


// router link change url
Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    const {
      asyncData
    } = this.$options
    if (asyncData) {
      asyncData({
        store: this.$store,
        route: to
      }).then(next).catch(next)
    } else {
      next()
    }
  }
})

const { app, router, store } = createApp()

// if server-side
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__)
} else {
  const matchedComponents = router.getMatchedComponents();
  if (!matchedComponents) {
    const err = new Error('404 Not Found')
    err.status = 404
    throw err
  }

  Promise.all(matchedComponents.map(component => {
    return component.asyncData && component.asyncData(store);
  })).then(() => {
    app.$mount('#japan-app')
  }).catch(e => {
    //TODO: finish catch
    console.error(e)
  })
}

router.onReady(() => {
  if (window && window.document.getElementById('app')) {
    app.$mount('#app')
  }
})
