<template>
  <div class="business-container">
    <div class="banner-img">
      <img src="../assets/4.png" alt="">
    </div>
    <div class="contianer">
      <!-- <div class="menu">
        <p><a href="#1">企業情報</a></p>
        <p><a href="#2">会社の沿革</a></p>
        <p><a href="#3">事業内容</a></p>
      </div> -->
      <div class="content">
        <!-- 企业情报 -->
        <div class="header">
          <p>企業情報</p>
        </div>
        <div class="info-content">
          <p>
            bilibili （上海本社）ビリビリは、中国の若い世代のためのオンラインエンターテインメントの象徴的なブランドです。2009年6月にウェブサイトが開設され、2010年1月に正式に「bilibili」と命名された当社は、高品質のコンテンツと臨場感あふれるエンターテインメント体験を提供し、ユーザーの強い感情的なつながりを基にプラットフォームを構築しました。
            アニメ、コミック、ゲーム、またはACGに触発されたコンテンツコミュニティとしてスタートし、ビデオ、ライブ放送、モバイルゲームなど、さまざまなジャンルやメディアフォーマットをカバーするフルスペクトラムのオンラインエンターテインメント界に進化しました。
          </p>
          <p>株式会社ビリビリ（日本支社）日本の優良なコンテンツを海外へ配給しており、ライセンシングの他、マーチャンダイズ展開、自社企画・制作、資金調達などを行う総合コンテンツプロデュース企業です。</p>
          <div class="detail">
            <p>代表者：陳睿（代表取締役兼CEO）</p>
            <p>設立年：2009（本社）</p>
            <p>株式公開：NASDAQ BILI；HKEX 9626</p>
            <p>従業員数連結 : 15320人   （2022年4月）</p>
            <p>所在地：</p>
            <p>本社：中国 上海</p>
            <p>日本支社：日本 東京</p>
          </div>
        </div>

        <!-- 会社の沿革 -->
        <div class="header">
          <p>会社の沿革</p>
        </div>
        <div class="annals-content">
          <div class="annals-item" v-for="(item, index) in annalsList" :key="index" :class="{ 'tag-item': item.tag }">
            <div class="icon">
            </div>
            <div class="date">
              {{item.date}}
            </div>
            <div class="desc">
              {{item.desc}}
            </div>
          </div>
        </div>
        
        <!-- 事業内容 -->
        <div class="header">
          <p>事業内容</p>
        </div>
        <div class="enterprise-content">
          <p>
            現在、日本の番組と提携して正式コンテンツとして配信しています。また、日本のアニメ系イベントに出展しました。また、bilibiliに連携して遊べるゲームアプリも併設されています。
            中国国内の大手配信サイトとしてこれらや、他国のサブカルに関して生放送でイベントを行いました。
          </p>

          <h3><b>製作作品（アニメ）</b></h3>
          <div class="list">
            <span>洲崎西 THE ANIMATION</span>
            <span>小森さんは断れない!</span>
            <span>不思議なソメラちゃん</span>
            <span>おじさんとマシュマロ</span>
            <span>大家さんは思春期!</span>
            <span>蒼の彼方のフォーリズム</span>
            <span>宇宙パトロールルル子</span>
            <span>パンでPeace!</span>
            <span>薄桜鬼 〜御伽草子〜</span>
            <span>腐男子高校生活</span>
            <span>にゃんぼー!</span>
            <span>バーナード嬢曰く。</span>
            <span>アイドルメモリーズ</span>
            <span>AKIBA'S TRIP -THE ANIMATION-</span>
            <span>霊剣山 叡智への資格</span>
            <span>アイドル事変</span>
            <span>恋愛暴君</span>
            <span>異世界食堂</span>
            <span>干物妹！うまるちゃんR</span>
            <span>URAHARA</span>
            <span>citrus</span>
            <span>ラーメン大好き小泉さん</span>
            <span>ヒナまつり</span>
            <span>異世界居酒屋「のぶ」</span>
            <span>ISLAND</span>
            <span>すのはら荘の管理人さん</span>
            <span>百錬の覇王と聖約の戦乙女</span>
            <span>叛逆性ミリオンアーサー</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>

import { mapState } from 'vuex'
export default {
  name: 'Business',
  data() {
    return {
      annalsList: [{
        date: '2009年6月26日',
        desc: '株式会社bilibili設立'
      }, {
        date: '2012年2月',
        desc: 'bilibiliモバイルアプリサービスイン'
      }, {
        date: '2013年10月5日',
        desc: '第一回BILIBILI MACRO LINK(BML)開催'
      }, {
        date: '2014年',
        desc: '日本支社設立',
        tag: true,
      }, {
        date: '2017年7月',
        desc: '第一回Bilibili World(BW)を主催'
      }, {
        date: '2018年3月28日',
        desc: 'bilibiliがNASDAQに上場。ティッカーシンボルはBILI'
      }, {
        date: '2018年',
        desc: 'テンセントがビリビリ株式を取得し、テンセントと戦略的パートナー契約を締結'
      }, {
        date: '2019年',
        desc: 'アリババグループがbilibiliの株式を取得'
      }, {
        date: '2021年3月29日',
        desc: 'bilibiliが香港証券取引市場に重複上場。ティッカーシンボルは9626'
      }]
    }
  },
  methods: {
    goPage({path}) {
      this.$router.push({
        path: path,
      })
    }
  },
}
</script>

<style lang="less">
.business-container {
  min-height: calc(100vh - 70px - 126px);
  .banner-img {
    width: 100%;
    height: 427px;
    border-radius: 8px;
    background: #eee;
    > img {
      display: inline-block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }

  .contianer {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
    .menu {
      margin-right: 72px;
      width: 105px;
      > p {
        margin-top: 10px;
        height: 50px;
        color: #0F0F0F;
        font-weight: 500;
        font-size: 16px;
      }
    }
    .content {
      flex: 1;
    }
  }
  .header {
    height: 45px;
    border-bottom: 1px solid #EAEAEA;
    color: #212121;
    line-height: 24px;
    line-height: 45px;
    // margin-top: 32px;
    > p {
      font-weight: 600;
      font-size: 28px;
    }
  }

  .info-content {
    margin-top: 34px;
    margin-bottom: 46px;
    color: #212121;
    font-size: 14px;
    line-height: 24px;
    > a {
      display: inline-block;
      margin-bottom: 20px;
      color: #3baee5;
      text-decoration: underline;
    }
    .detail {
      margin-top: 20px;
    }
  }

  .annals-content {
    margin-top: 32px;
    margin-bottom: 46px;
    .annals-item {
      display: flex;
      align-items: center;
      margin-bottom: 35px;

      &:last-child .icon::after {
        display: none;
      }
    }
    .tag-item {
      font-weight: 700;
      .desc {
        color: #32A4D5;
      }
    }
    .icon {
      position: relative;
      flex-shrink: 0;
      margin-right: 14px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #32A4D5;
      color: #32A4D5;
      &::after {
        position: absolute;
        top: 10px;
        left: 4px;
        width: 1px;
        height: 48px;
        background: #32A4D5;
        content: '';
      }
    }
    .date {
      flex-shrink: 0;
      width: 160px;
      color: #32A4D5;
      font-size: 16px;
    }
    .desc {
      font-size: 14px;
    }
  }

  .enterprise-content {
    margin-bottom: 46px;
    color: #212121;
    font-size: 14px;
    > p {
      margin: 30px 0;
    }
    h3 {
      margin-bottom: 10px;
    }
    .list {
      > span {
        display: inline-block;
        margin: 10px 15px 5px 0;
      }
    }
  }


}
</style>
