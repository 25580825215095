<template>
  <div class="homepage-container">
    <div class="banner-img">
      <img src="../assets/business_banner.png" alt="">
    </div>
    <div class="header">
      <p>企業情報</p>
    </div>
    <div class="info-content">
      ビリビリは、中国の若い世代のためのオンラインエンターテインメントの象徴的なブランドです。
      2009年6月にウェブサイトが開設され、2010年1月に正式に「bilibili」と命名された当社は、高品質のコンテンツと臨場感あふれるエンターテインメント体験を提供し、ユーザーの強い感情的なつながりを基にプラットフォームを構築しました。
    </div>

    <!-- <div v-if="newsList && newsList.length" class="header">
      <p>最近のニュース</p>
    </div> -->
    <!-- <div v-if="newsList && newsList.length" class="new-content">
      <div class="new-item" v-for="(news, index) in newsList" :key="index"
          @click="$router.push({name: 'NewsDetail', params: { id: news.id }})">
        <a class="title" :title="news.title">{{ news.title }}</a>
        <p class="date">{{ formatDate(news.ctime) }}</p>
      </div>
    </div> -->

    <!-- <div class="header">
      <p>アニメ作品</p>
    </div>
    <div class="manga-content">
      <el-popover
        class="manga-card-container" v-for="(manga, index) in mangaData.list" :key="index"
        placement="top"
        :disabled="clientWidth <= 1024"
        popper-class="manga-popper"
        trigger="hover">
        <div class="manga-popper-card">
          <img :src="manga.img" @error="mangaError">
          <div class="info">
            <p class="title">{{ manga.title }}</p>
            <p v-if="manga.author">原作：{{ manga.author }}</p>
            <p v-if="manga.director">导演：{{ manga.director }}</p>
            <p v-if="manga.supervision">監督：{{ manga.supervision }}</p>
            <p v-if="manga.set">配信：{{ manga.set }}</p>
            <p v-if="manga.company">制作会社：{{ manga.company }}</p>
            <p v-if="manga.link">公式サイト：<a :href="manga.link" target="_blank">{{ manga.link }}</a></p>
            <p v-if="manga.info1">{{ manga.info1 }}</p>
            <p v-if="manga.info2">{{ manga.info2 }}</p>
            <p v-if="manga.info3">{{ manga.info3 }}</p>
          </div>
        </div>
        <a slot="reference" :href="manga.link" target="_blank" class="manga-card">
          <div class="pic">
            <img :src="manga.img" @error="mangaError">
          </div>
          <p class="title">{{ manga.title }}</p>
        </a>
      </el-popover>
    </div> -->

    <!-- <div class="header">
      <p>ゲーム事業</p>
    </div>
    <div class="game-content">
      <div class="game-card">
        <div class="pic">
          <img src="../assets/game01.png">
        </div>
        <div class="info">
          <p class="title">魂器学院-HorcruxCollege </p>
          <p class="desc">美少女を育成する放置系RPG！2020年サービス開始予定！</p>
        </div>
      </div>
    </div> -->
  </div>
</template>


<script>

import { mapState } from 'vuex'
import mangaData from '../assets/data/manga.json'
import { formatDate } from '../utils/index'

export default {
  name: 'Homepage',
  data() {
    return {
      mangaData,
      newsList: [],
      visible: true,
      clientWidth: document.body.clientWidth || window.screen.width || window.innerWidth || 375,
    }
  },
  created () {
    this.loadNews()
  },
  methods: {
    formatDate,
    goPage({path}) {
      this.$router.push({
        path: path,
      })
    },
    mangaError(e) {
      e.target.style = 'display: none'
    },
    async loadNews() {
      try {
        const data = await this.$axios.get(`//api.bilibili.com/x/cms/news`, {
          params: {
            business_id: 1,
            pn: 1,
            ps: 4
          }
        })
        if(data.code === 0) {
          this.newsList = data.data.news_list
        }
      } catch (error) {
      }
    },
  },
}
</script>

<style lang="less">
.homepage-container {
  min-height: calc(100vh - 70px - 126px);
  .banner-img {
    width: 100%;
    height: 427px;
    border-radius: 8px;
    background: #eee;
    > img {
      display: inline-block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }
  .header {
    margin-top: 32px;
    height: 45px;
    border-bottom: 1px solid #EAEAEA;
    color: #212121;
    line-height: 24px;
    line-height: 45px;
    > p {
      font-weight: 600;
      font-size: 28px;
    }
  }

  .info-content {
    margin-top: 34px;
    color: #212121;
    font-size: 14px;
    line-height: 24px;
    > a {
      display: block;
      color: #32A4D5;
    }
  }

  .new-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 34px;
    color: #212121;
    font-size: 14px;
    line-height: 24px;
    .new-item {
      margin-bottom: 20px;
      width: 49%;
      cursor: pointer;
      &:hover .title {
        color: #32A4D5 ;
      }
      .title {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 20px;
        transition: .3s;
      }
      .date {
        color: #999999;
        font-size: 14px;
      }
    }
  }

  .manga-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 34px;
    .manga-card-container {
      flex-shrink: 0;
      width: 160px;
      height: 268px;
      .pic {
        margin-bottom: 4px;
        width: 100%;
        height: 214px;
        border-radius: 4px;
        background: #eee;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .title {
        color: #212121;
        font-size: 18px;
      }
      .desc {
        color: #999999;
        font-size: 14px;
      }
    }
  }

  .game-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 34px;
    margin-bottom: 46px;
    .game-card {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      width: 336px;
      height: 100px;
      .pic {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        background: #eee;
        > img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
        }
      }
      .info {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        margin-left: 18px;
        width: 218px;
        .title {
          color: #212121;
          font-size: 18px;
        }
        .desc {
          color: #999999;
          font-size: 14px;
        }
      }
    }
  }
}

.manga-popper {
  width: 600px;
  .manga-popper-card {
    display: flex;
    padding: 35px;
    > img {
      flex-shrink: 0;
      width: 160px;
      height: 214px;
      border-radius: 4px;
    }
    .info {
      padding: 0 18px;
      color: #212121;
      color: #212121;
      letter-spacing: 0;
      font-size: 14px;
      font-size: 14px;
      font-family: PingFangTC-Medium;
      line-height: 36px;
      .title {
        font-size: 28px;
      }
      a {
        color: #32A4D5;
      }
    }
  }
}
</style>
